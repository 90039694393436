/*
* DashForge v1.0.0 (https://themepixels.me/dashforge)
* Copyright 2019 ThemePixels
* Licensed under ThemeForest License
*
*

/* ############### FONTS IMPORT ############### */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400');

// Local font
@import "fonts/ibm-plex-sans";
@import "fonts/inter-ui";

// Variables
@import "variables";
@import "bootstrap/variables";

// Mixins
@import "mixins";
@import "bootstrap/mixins";

/* ############### BOOTSTRAP FRAMEWORK ############### */
@import "~bootstrap/scss/bootstrap.scss";


/* ############### BOOTSTRAP OVERRIDES ############### */
@import "bootstrap/reboot";
@import "bootstrap/alert";
@import "bootstrap/badge";
@import "bootstrap/breadcrumb";
@import "bootstrap/buttons";
@import "bootstrap/card";
@import "bootstrap/carousel";
@import "bootstrap/custom-forms";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/grid";
@import "bootstrap/input-group";
@import "bootstrap/list-group";
@import "bootstrap/modal";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/pagination";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/toast";
@import "bootstrap/tooltip";
@import "bootstrap/table";

/* ############### ADDON COMPONENTS STYLES ############### */
@import "dashforge/accordion";
@import "dashforge/avatar";
@import "dashforge/button-group";
@import "dashforge/card";
@import "dashforge/divider";
@import "dashforge/dropdown";
@import "dashforge/image";
@import "dashforge/link";
@import "dashforge/list";
@import "dashforge/marker";
@import "dashforge/modal";
@import "dashforge/nav";
@import "dashforge/off-canvas";
@import "dashforge/overlay";
@import "dashforge/placeholder";
@import "dashforge/search";
@import "dashforge/steps";
@import "dashforge/tab";

/* ############### PLUGINS STYLES ############### */
@import "lib/datatables";
@import "lib/datepicker";
@import "lib/flot";
@import "lib/jquery-steps";
@import "lib/morris";
@import "lib/parsley";
@import "lib/jqvmap";
@import "lib/quill";
@import "lib/rangeslider";
@import "lib/scrollbar";
@import "lib/select2";
@import "lib/spectrum";
@import "lib/tagsinput";
@import "lib/typeahead";

/* ############### PANEL STYLES ############### */
@import "panels/backdrop";
@import "panels/header";
@import "panels/logo";
@import "panels/sidebar";
@import "panels/aside";
@import "panels/content";
@import "panels/footer";
@import "panels/settings";


/* ############### UTILITIES/HELPER CLASSES ############### */
@import "util/background";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/misc";
@import "util/padding";
@import "util/position";
@import "util/transform";
@import "util/typography";
@import "util/width";
