@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 100;
  src: url("../../fonts/inter-ui/Inter-UI-Thin-BETA.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-Thin-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 100;
  src: url("../../fonts/inter-ui/Inter-UI-ThinItalic-BETA.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-ThinItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 200;
  src: url("../../fonts/inter-ui/Inter-UI-ExtraLight-BETA.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-ExtraLight-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 200;
  src: url("../../fonts/inter-ui/Inter-UI-ExtraLightItalic-BETA.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-ExtraLightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 300;
  src: url("../../fonts/inter-ui/Inter-UI-Light-BETA.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-Light-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 300;
  src: url("../../fonts/inter-ui/Inter-UI-LightItalic-BETA.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-LightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 400;
  src: url("../../fonts/inter-ui/Inter-UI-Regular.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 400;
  src: url("../../fonts/inter-ui/Inter-UI-Italic.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 500;
  src: url("../../fonts/inter-ui/Inter-UI-Medium.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 500;
  src: url("../../fonts/inter-ui/Inter-UI-MediumItalic.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 600;
  src: url("../../fonts/inter-ui/Inter-UI-SemiBold.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 600;
  src: url("../../fonts/inter-ui/Inter-UI-SemiBoldItalic.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 700;
  src: url("../../fonts/inter-ui/Inter-UI-Bold.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 700;
  src: url("../../fonts/inter-ui/Inter-UI-BoldItalic.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 800;
  src: url("../../fonts/inter-ui/Inter-UI-ExtraBold.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 800;
  src: url("../../fonts/inter-ui/Inter-UI-ExtraBoldItalic.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 900;
  src: url("../../fonts/inter-ui/Inter-UI-Black.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-Black.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 900;
  src: url("../../fonts/inter-ui/Inter-UI-BlackItalic.woff2") format("woff2"),
       url("../../fonts/inter-ui/Inter-UI-BlackItalic.woff") format("woff");
}
